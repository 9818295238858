import { Target, TypedController } from "@vytant/stimulus-decorators";
import { Controller } from "stimulus";
import "./component.scss";
import $ from "jquery";
import "selectize";

@TypedController
export default class extends Controller {
  @Target submitRowTarget: HTMLButtonElement;
  private initialValues: Map<string, string[]> = new Map();

  connect() {
    this.initializeInputListeners();
    this.initializeCountryDropdownListener();
  }

  private initializeInputListeners() {
    this.element
      .querySelectorAll("input, select, textarea")
      .forEach((input: HTMLElement) => {
        if (
          input instanceof HTMLSelectElement &&
          $(input).hasClass("selectize-field")
        ) {
          this.initializeSelectize(input);
        } else {
          input.addEventListener("input", this.showSubmitButton.bind(this));
        }
      });
  }

  /**
   * We need this to initialize the selectize.js multi-select field
   * We need to use the selectizeInstance.items to get the current value
   * We need to compare the initial value with the current value to determine if the submit button should be shown
   */
  initializeSelectize(select: HTMLSelectElement) {
    const $select = $(select);
    const selectizeInstance = ($select as any)[0].selectize;
    if (selectizeInstance) {
      this.initialValues.set(select.id, selectizeInstance.items.slice());
      selectizeInstance.on(
        "change",
        this.handleSelectizeChange.bind(this, select.id)
      );
    }
  }

  handleSelectizeChange(selectId: string, value: string) {
    const $select = $(`#${selectId}`);
    const selectizeInstance = ($select as any)[0].selectize;
    const initialValue = this.initialValues.get(selectId) || [];
    const currentValue = selectizeInstance.items;

    if (!this.arraysEqual(initialValue, currentValue)) {
      this.showSubmitButton();
    }
  }

  arraysEqual(a: string[], b: string[]): boolean {
    if (a.length !== b.length) return false;
    return a.every((val, index) => val === b[index]);
  }

  // For the phone number field (country select)
  private initializeCountryDropdownListener() {
    const phoneInput = this.element.querySelector('input[type="tel"]');
    if (phoneInput) {
      phoneInput.addEventListener(
        "countrychange",
        this.showSubmitButton.bind(this)
      );
    }

    document.addEventListener("click", (event) => {
      const target = event.target as HTMLElement;
      if (target.closest(".iti__country")) {
        this.showSubmitButton();
      }
    });
  }

  showSubmitButton() {
    this.submitRowTarget.classList.remove("tw-hidden");
  }
}
