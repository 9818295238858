import {Target, TypedController, Value} from "@vytant/stimulus-decorators";
import {addSeconds, formatDistanceStrict} from "date-fns";
import {de, enGB, fr, nlBE, uk, zhCN} from "date-fns/locale";
import {Controller} from "stimulus";

@TypedController
export default class extends Controller {
  @Target readonly passwordInputTarget: HTMLInputElement;
  @Target readonly sendMagicLinkButtonTarget: HTMLButtonElement;
  @Target readonly submitButtonTarget!: HTMLButtonElement;
  @Target readonly remainingTimeLockedMessageTarget: HTMLElement;

  @Value(String) secondsRemainingLockedValue: number
  @Value(String) currentLanguageValue: string

  connect() {
    this.passwordInputTarget.addEventListener("input", this.updateSubmitButtonVisibility.bind(this));

    // @ts-ignore StimulusDecorators default values are broken 🙄
    if (this.secondsRemainingLockedValue === '') this.secondsRemainingLockedValue = 0;
    if (this.currentLanguageValue === '') this.currentLanguageValue = 'de';

    if (this.secondsRemainingLockedValue > 0) {
      this.disablePasswordInputAndCountdown();
    }
  }

  updateSubmitButtonVisibility() {
    const value = this.passwordInputTarget.value;
    if (value.length > 0) {
      this.submitButtonTarget.removeAttribute("disabled");
    } else {
      this.submitButtonTarget.setAttribute("disabled", "true");
    }
  }

  disablePasswordInputAndCountdown() {
    this.setRemainingTimeLockedMessage();

    const interval = setInterval(() => {
      this.secondsRemainingLockedValue = this.secondsRemainingLockedValue - 1;

      if (this.secondsRemainingLockedValue <= 0) {
        clearInterval(interval);

        this.passwordInputTarget.disabled = false;
        this.sendMagicLinkButtonTarget.disabled = false;
        this.remainingTimeLockedMessageTarget.classList.add("hidden");
      }

      this.setRemainingTimeLockedMessage();
    }, 1000);
  }

  setRemainingTimeLockedMessage() {
    const timeSpan = this.remainingTimeLockedMessageTarget.querySelector("#remaining-time");

    const time = addSeconds(new Date(), this.secondsRemainingLockedValue);
    timeSpan!.innerHTML = formatDistanceStrict(new Date(), time, {locale: this.dateFnsLocale(this.currentLanguageValue)});
  }

  dateFnsLocale(lang: string) {
    switch (lang) {
      case "de":
        return de;
      case "en":
        return enGB;
      case "fr":
        return fr;
      case "nl":
        return nlBE;
      case "uk":
        return uk;
      case "zh":
        return zhCN;
      default:
        return de;
    }
  }
}
