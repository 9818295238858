import { Controller } from "@hotwired/stimulus";
import { Target, TypedController, Value } from "@vytant/stimulus-decorators";
import type { TurboFrame } from "~/types";

@TypedController
export default class extends Controller {
  @Target overlayTarget: HTMLDivElement;

  @Target drawerTarget: HTMLDivElement;
  @Target loadingTarget: HTMLDivElement;

  @Target titleTarget: HTMLDivElement;
  @Target linkTarget: HTMLAnchorElement;
  @Target linkOldTarget: HTMLAnchorElement;

  @Value(String) readonly modelPathValue!: string;

  connect() {
    document.addEventListener("keydown", (event) => {
      if (event.key === "Escape") {
        this.closeShedit();
      }
    });
  }

  openShedit(event: any) {
    const target = event.target instanceof HTMLSpanElement ? event.target.parentElement : event.target;
    const id = target?.dataset.id;
    if (!id) {
      return;
    }

    // start loading, hide frame and show loader
    this.titleTarget.textContent = target?.dataset.title || "[No title]";
    this.frameTarget.dataset.id = id;
    this.frameTarget.classList.add("hidden");
    this.loadingTarget.classList.remove("hidden");
    this.frameTarget.src = `/dashboard/${this.modelPathValue}/${id}?frame=true`;
    this.drawerTarget.classList.remove("hidden");

    // update URLs
    const tmpUrl = new URL(window.location.href);
    tmpUrl.searchParams.set("id", id);
    window.history.replaceState(null, "", tmpUrl.toString());
    this.linkTarget.href = `/dashboard/${this.modelPathValue}/${id}`;
    this.linkOldTarget.href = `/admin/${this.modelPathValue}/${id}`;

    // on load remove loader and show frame
    this.frameTarget.loaded.then(() => {
      this.frameTarget.classList.remove("hidden");
      this.loadingTarget.classList.add("hidden");
    });

    this.overlayTarget.classList.remove("hidden");
  }

  closeShedit() {
    this.drawerTarget.classList.add("hidden");

    const tmpUrl = new URL(window.location.href);
    tmpUrl.searchParams.delete("id");
    tmpUrl.searchParams.delete("tab");
    tmpUrl.searchParams.delete("history_page");
    tmpUrl.searchParams.delete("history_per_page");

    window.history.replaceState(null, "", tmpUrl.toString());

    this.overlayTarget.classList.add("hidden");
  }

  private get frameTarget() {
    return this.element.querySelector<TurboFrame>("#shedit-frame")!;
  }
}
